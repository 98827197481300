"use client";
import { Flex, Image, Stack, Text, Title } from "@mantine/core";
import { useIsMobile } from "@/shared/lib/hooks";
import {
  DragonBar,
  Gamochnaya,
  Mazaltov,
  MoonEclipse,
  Myata,
  Yonks,
  academy,
  blinberi,
  coctailnaya,
  colaboration,
  sushivesla,
} from "@/shared/model";
import styles from "./styles.module.css";
const OurPartners = () => {
  const isMobile = useIsMobile(600);
  const is900 = useIsMobile(900);
  const brands = [
    {
      name: "Yonks",
      icon: (
        <Yonks
          style={{
            height: isMobile ? 22 : "100%",
            width: isMobile ? 24 : "100%",
          }}
        />
      ),
    },
    {
      name: "Dragon Mixology Bar",
      icon: (
        <Image
          alt="Dragon Mixology Bar"
          title="Dragon Mixology Bar"
          h="100%"
          src={DragonBar.src}
        />
      ),
    },
    {
      name: "Мазалтов",
      icon: (
        <Mazaltov
          style={{
            height: isMobile ? 27 : "100%",
            width: isMobile ? 24 : "100%",
          }}
        />
      ),
    },
    {
      name: "Гамачная #1",
      icon: (
        <Image
          alt="Гамачная #1"
          title="Гамачная #1"
          h="100%"
          src={Gamochnaya.src}
        />
      ),
    },
    {
      name: "Moon eclipse",
      icon: (
        <Image
          alt="Moon eclipse"
          title="Moon eclipse"
          h="100%"
          src={MoonEclipse.src}
        />
      ),
    },
    {
      name: "Мята Lounge",
      icon: (
        <Image
          alt="Мята Lounge"
          title="Мята Lounge"
          h="100%"
          style={{ borderRadius: "100%" }}
          src={Myata.src}
        />
      ),
    },
    {
      name: "блинбери",
      icon: (
        <Image alt="блинбери" title="блинбери" h="100%" src={blinberi.src} />
      ),
    },
    {
      name: "Академия Эпиляции",
      icon: (
        <Image
          alt="Академия Эпиляции"
          title="Академия Эпиляции"
          h="100%"
          style={{ borderRadius: "100%" }}
          src={academy.src}
        />
      ),
    },
    {
      name: "сушивёсла",
      icon: (
        <Image
          alt="сушивёсла"
          title="сушивёсла"
          h="100%"
          src={sushivesla.src}
        />
      ),
    },
    {
      name: "Коллаборация",
      icon: (
        <Image
          alt="Коллаборация"
          title="Коллаборация"
          h="100%"
          src={colaboration.src}
        />
      ),
    },
    {
      name: "Коктейльная",
      icon: (
        <Image
          alt="Коктейльная"
          title="Коктейльная"
          h="100%"
          src={coctailnaya.src}
        />
      ),
    },
  ];
  return (
    <Stack
      justify="space-between"
      mt={is900 ? 16 : 180}
      gap={isMobile ? 16 : 44}
    >
      <Title ta="center" fw={700} fz={isMobile ? 28 : 44}>
        Наши{" "}
        <span
          style={{
            background: "linear-gradient(90deg, #3ED2FF, #413EFF)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          партнёры
        </span>
      </Title>
      <Flex wrap="wrap" justify="center" gap={isMobile ? 16 : 24}>
        {brands.map((item) => (
          <Flex
            gap={isMobile ? 4 : 12}
            key={item.name}
            className={styles.item}
            align="center"
            justify="center"
            w="fit-content"
            py={isMobile ? 12 : 20}
            h={isMobile ? 43 : 76}
            px={isMobile ? 14 : 32}
            style={{
              borderRadius: "44px",
            }}
            bg="white"
          >
            {item.icon}
            <Text fz={isMobile ? 12 : 20} fw={500}>
              {item.name}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Stack>
  );
};

export default OurPartners;
