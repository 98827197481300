"use client";
import {
  Box,
  Button,
  Fieldset,
  Image,
  Modal,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { isNotEmpty, matches, useForm } from "@mantine/form";

import { FlashImg, PotPinkImg } from "@/shared/model/images";

import { useDisclosure, useIsFirstRender } from "@mantine/hooks";

import { X } from "react-feather";
import { Fail3D, Success3D } from "@/shared/model";
import styles from "./styles.module.css";
import { TextInputWithMask } from "@/shared/ui/inputs";

const ConnectForm = () => {
  const isFirstRender = useIsFirstRender();

  const [
    successModalOpened,
    { open: openSuccessModal, close: closeSuccessModal },
  ] = useDisclosure(false);

  const [errorModalOpened, { open: openErrorModal, close: closeErrorModal }] =
    useDisclosure(false);

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      orgName: "",
      phoneNumber: "",
    },

    validate: {
      orgName: isNotEmpty(),
      phoneNumber: matches(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/),
    },
  });

  const handleSubmit = async (values: {
    orgName: string;
    phoneNumber: string;
  }) => {
    const formattedValues = {
      ...values,
      phoneNumber: values.phoneNumber.replace(/[^0-9]/g, ""),
    };

    try {
      const response = await fetch(
        `https://alert.${window.location.host}/send_org_application`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "86859005-b87f-4d71-8f3a-70b03cf697e1",
          },
          body: JSON.stringify(formattedValues),
        }
      );

      if (response.ok) {
        openSuccessModal(); // Успех
        form.reset();
      } else {
        openErrorModal(); // Ошибка
      }
    } catch (error) {
      openErrorModal(); // Ошибка
    }
  };

  return (
    <>
      <Modal
        radius={44}
        closeButtonProps={{
          icon: <X size={40} />,
        }}
        styles={{
          close: { right: 10, top: 10 },
        }}
        size="536px"
        opened={successModalOpened}
        onClose={closeSuccessModal}
      >
        <Stack justify="center" gap={30} p="0 60px 40px 60px" align="center">
          <Box w={120} h={120}>
            <Image
              src={Success3D.src}
              title="success"
              alt="success"
              fit="contain"
              h="100%"
            />
          </Box>

          <Stack gap={4} align="center" style={{ textWrap: "nowrap" }}>
            <Text ta="center" fw={700} fz={28}>
              Спасибо!
            </Text>
            <Text ta="center" fz={20} fw={400}>
              Данные успешно отправлены
            </Text>
          </Stack>
        </Stack>
      </Modal>

      <Modal
        radius={44}
        closeButtonProps={{
          icon: <X size={40} />,
        }}
        styles={{ close: { right: 10, top: 10 } }}
        size="536px"
        opened={errorModalOpened}
        onClose={closeErrorModal}
      >
        <Stack justify="center" gap={30} p="0 50px 20px 50px" align="center">
          <Box w={74} h={132} mt={-20}>
            <Image
              src={Fail3D.src}
              alt="fail"
              title="fail"
              fit="contain"
              h="100%"
            />
          </Box>
          <Stack gap={4} align="center">
            <Text ta="center" fw={700} fz={24}>
              Упс, что-то пошло не так
            </Text>
            <Text ta="center" fz={20} fw={400}>
              Попробуйте повторить действие через несколько минут
            </Text>
          </Stack>
        </Stack>
      </Modal>

      <form
        style={{
          display: "flex",
          alignSelf: "center",
          position: "relative",
          justifyContent: "center",
          width: "100%",
          margin: "40px 0",
        }}
        id="contact-form"
        onSubmit={form.onSubmit(handleSubmit)}
      >
        <Fieldset
          style={{ rowGap: 24 }}
          w={{ base: "100%", xs: 536 }}
          p={{ base: "40px 16px", sm: 40 }}
          variant="contact-form"
          mod="flex"
        >
          <Stack gap={0}>
            <Title
              order={2}
              fw={700}
              fz={{ base: 24, sm: 28 }}
              lh={1.25}
              textWrap="nowrap"
            >
              Подключите Чайник
            </Title>
            <Text
              c="textGray"
              ta="center"
              fz={{ base: 14, sm: 16 }}
              pt={{ base: 6, sm: 10 }}
            >
              или узнайте больше о продукте
            </Text>
          </Stack>
          <Stack w="100%" gap={20}>
            <TextInput
              key={form.key("orgName")}
              {...form.getInputProps("orgName")}
              placeholder="Название организации"
              variant="connect-form-input"
              onFocus={() =>
                form.errors.orgName && form.setFieldError("orgName", "")
              }
            />
            <TextInputWithMask
              key={form.key("phoneNumber")}
              {...form.getInputProps("phoneNumber")}
              mask="+7 (000) 000-00-00"
              variant="connect-form-input"
              placeholder="+7 (123) 123-12-31"
              onFocus={() =>
                form.errors.phoneNumber && form.setFieldError("phoneNumber", "")
              }
            />
            <Text c="textGray" fz={12} mt={-14}>
              * Нажимая кнопку, я даю согласие на обработку персональных данных
            </Text>
          </Stack>
          <Button
            type="submit"
            variant="connect-form-btn"
            w="100%"
            fw={700}
            fz={18}
          >
            Подключить заведение
          </Button>
        </Fieldset>
        <Box
          w={525}
          h={525}
          pos="absolute"
          style={{ zIndex: 20 }}
          bottom={-280}
          right="100%"
          className={styles.box}
          mod={{ background: "flash" }}
          visibleFrom="smd"
        >
          <Image
            alt="flash"
            title="flash"
            fit="contain"
            h="100%"
            src={FlashImg.src}
          />
        </Box>
        <Box
          w={310}
          h={310}
          pos="absolute"
          style={{ zIndex: 20 }}
          top={80}
          left="100%"
          className={styles.box}
          mod={{ background: "potPink" }}
          visibleFrom="smd"
        >
          <Image
            alt="flash"
            title="flash"
            fit="contain"
            h="100%"
            src={PotPinkImg.src}
          />
        </Box>
      </form>
    </>
  );
};

export default ConnectForm;
