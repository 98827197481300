import { TextInput, TextInputProps } from '@mantine/core';
import { IMaskInput, IMaskInputProps } from 'react-imask';

type Props = Omit<TextInputProps, 'component'> &
  IMaskInputProps<HTMLInputElement>;

const TextInputWithMask = (props: Props) => {
  return <TextInput component={IMaskInput} {...props} />;
};

export default TextInputWithMask;
