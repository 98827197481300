import { Stack, Text, Title } from "@mantine/core";
import { useIsMobile } from "@/shared/lib/hooks";

type Props = {
  title: string;
  description: string;
  image: string | React.ReactNode;
  imageHeight?: number;
};

const FacilitiesCard = ({ image, description, title, imageHeight }: Props) => {
  const isMobile = useIsMobile(600);
  return (
    <Stack
      px={24}
      py={18}
      bg="white"
      pos="relative"
      h={isMobile ? 343 : 387}
      style={{ overflow: "hidden", borderRadius: "24px" }}
      w="100%"
    >
      {image}
      <Stack style={{ zIndex: 1 }} p={0} gap={12} mt="auto">
        <Title ta={isMobile ? "center" : "left"} fz={24} fw={700} lh="28px">
          {title}
        </Title>
        <Text
          fz={isMobile ? 14 : 16}
          ta={isMobile ? "center" : "left"}
          lh="24px"
          c="textGray"
          fw={400}
        >
          {description}
        </Text>
      </Stack>
    </Stack>
  );
};

export default FacilitiesCard;
