"use client";
import {
  Box,
  Flex,
  Image,
  Paper,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useIsMobile } from "@/shared/lib/hooks";
import { PageGuest, PageGuest2, TipsQr } from "@/shared/model";
import { Condition } from "@/shared/ui/display";

import { LandingBg } from "@/shared/model/images";

export const EasyTips = () => {
  const is600 = useIsMobile(600);
  const isMobile = useIsMobile(900);
  const is1320 = useIsMobile(1320);
  const is1415 = useIsMobile(1415);
  const contentCard = [
    {
      id: 1,
      title: "Чаевые по QR-коду",
      text: "Наведите камеру на QR-код",
      img: (
        <Image
          alt="Чаевые по QR-коду"
          title="Чаевые по QR-коду"
          display="initial"
          h="100%"
          w={"100%"}
          pr={20}
          // w={{ md: 240, base: 200 }}
          src={TipsQr.src}
          style={{ objectFit: "contain" }}
        />
      ),
    },
    {
      id: 2,
      title: "Оценка и сумма",
      text: "Выберите сумму и оцените сотрудника",
      img: (
        <Image
          w={"100%"}
          alt="Оценка и сумма"
          title="Оценка и сумма"
          h="100%"
          style={{ objectFit: "contain" }}
          // style={{ objectFit: `${isMobile ? 'initial' : 'cover'}` }}
          // w={{ sm: '100%', base: 200 }}
          display="initial"
          src={PageGuest.src}
        />
      ),
    },
    {
      id: 3,
      title: "Оплата!",
      text: "Выберите удобный способ оплаты",
      img: (
        // <BackgroundImage h='100%' ta='end' src={EllipseBg}>
        <Image
          alt="Оплата!"
          title="Оплата!"
          display="initial"
          h="100%"
          w={"100%"}
          style={{
            objectFit: "contain",
            position: "absolute",
            right: -30,
            transform: "scale(1.27)",
            top: -150,
          }}
          // style={{ objectFit: `${isMobile ? 'initial' : 'cover'}` }}
          // w={{ md: '100%', base: 261 }}
          src={PageGuest2.src}
        />
        // </BackgroundImage>
      ),
    },
  ];

  return (
    <>
      <Condition when={!isMobile}>
        <Flex
          h={{ sm: 790, base: 690 }}
          mt={50}
          direction="column"
          gap={{ base: 24, sm: 64 }}
          id="easy-tips"
          pos="relative"
        >
          <Box
            pt={58}
            h={524}
            style={{
              borderRadius: "44px",
              backgroundImage: `url(${LandingBg.src})`,
            }}
          >
            <Title order={2} ta="center" c="white" fz={44}>
              Чаевые — это просто!
            </Title>
          </Box>
          <Flex
            justify={{ md: "space-between", base: "center" }}
            w="100%"
            gap={{ lg: 24, base: 24 }}
            px={{ lg: 45, md: 16, base: 0 }}
            top="170px"
            pos="absolute"
          >
            {contentCard.map((item) => (
              <Paper
                mah={is1415 ? 500 : 491}
                key={item.title}
                radius={44}
                display="flex"
                style={{
                  justifyContent: "space-between",
                  flexDirection: "column",
                  gap: "28px",
                  overflow: "hidden",
                }}
                pos="relative"
                w={370}
                pb={24}
              >
                {item.img}

                <Stack
                  h={is1415 ? (is1320 ? 135 : 115) : 100}
                  px={24}
                  gap={10}
                  pos="absolute"
                  bottom={0}
                  bg="white"
                  w="100%"
                  style={{
                    zIndex: 1,
                    boxShadow: "-10px 0px 20px 40px rgba(255, 255, 255, 1)",
                  }}
                >
                  <Title order={2} fz={{ lg: 28, base: 24 }} fw={700}>
                    {item.title}
                  </Title>
                  <Text c="gray">{item.text}</Text>
                </Stack>
              </Paper>
            ))}
          </Flex>
        </Flex>
      </Condition>

      <Condition when={isMobile}>
        <Stack id="easy-tips" gap={24}>
          <Title
            order={2}
            ta="center"
            c="black"
            mt={16}
            fz={is600 ? 20 : 44}
            fw={700}
          >
            Чаевые — это просто!
          </Title>
          {contentCard.map((item) => (
            <div className="embla__slide" key={item.id}>
              <div className="embla__slide__number">
                <Paper
                  pos="relative"
                  h={464}
                  w="100%"
                  radius={44}
                  display="flex"
                  style={{
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: "19px",
                    overflow: "hidden",
                  }}
                  pb={24}
                >
                  {item.img}

                  <Stack
                    h={is600 ? 100 : 135}
                    px={24}
                    gap={10}
                    pos="absolute"
                    bottom={0}
                    bg="white"
                    w="100%"
                    style={{
                      zIndex: 1,
                      boxShadow: "-10px 0px 15px 30px rgba(255, 255, 255, 1)",
                    }}
                  >
                    <Title
                      order={2}
                      fz={28}
                      ta={is600 ? "center" : "left"}
                      fw={700}
                    >
                      {item.title}
                    </Title>
                    <Text
                      fz={is600 ? 14 : 16}
                      ta={is600 ? "center" : "left"}
                      c="gray"
                    >
                      {item.text}
                    </Text>
                  </Stack>
                </Paper>
              </div>
            </div>
          ))}
        </Stack>
      </Condition>
    </>
  );
};
