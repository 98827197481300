"use client";
import {
  Anchor,
  Box,
  Burger,
  Button,
  Flex,
  Image,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { JSX, useEffect, useRef, useState } from "react";
import { AppMainImage, BgLights, LogoTipsImg } from "@/shared/model";
import { equals } from "ramda";
import { usePathname, useRouter } from "next/navigation";
import IntroWindow from "../windows/intro-window";
import { useIsMobile } from "@/shared/lib/hooks";
interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: "accepted" | "dismissed" }>;
}
const Intro = ({
  dataControls,
}: {
  dataControls: { text: string; href: string }[];
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const [menuOpened, setMenuOpened] = useState(false); // Состояние для управления меню
  const [isLoading, setIsLoading] = useState(true); // Состояние для управления загрузкой
  const [supportsPWA, setSupportsPWA] = useState(false);
  const isMobile = useIsMobile(900);
  const [promptInstall, setPromptInstall] =
    useState<BeforeInstallPromptEvent | null>(null);

  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      console.log(e, "callback inside effect");
      e.preventDefault();
      setPromptInstall(e as BeforeInstallPromptEvent);
      setSupportsPWA(true);
    });
  }, []);

  const handleInstallClick = () => {
    promptInstall && promptInstall.prompt();
    setPromptInstall(null);
    setSupportsPWA(false);
  };
  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
  //       closeMenu();
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    if (menuOpened) {
      document.body.style.scrollBehavior = "none";
    } else {
      document.body.style.scrollBehavior = "auto";
    }
  }, [menuOpened]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    // Симуляция загрузки страницы
    const timer = setTimeout(() => {
      setIsLoading(false);
      document.body.style.overflow = "auto";
    }, 2000); // Задержка в 2 секунды для демонстрации

    return () => clearTimeout(timer);
  }, []);

  const toggleMenu = () => {
    setMenuOpened((prev) => !prev);
  };
  const closeMenu = () => setMenuOpened(false);

  return (
    <>
      {isLoading && <IntroWindow />}
      <Flex
        bg="rgba(242, 243, 249, 0.62)"
        px={{ base: 16, sm: 20, lg: 60 }}
        pos="fixed"
        w="100%"
        left="50%"
        style={{
          backdropFilter: "blur(50px)",
          WebkitBackdropFilter: "blur(50px)",
          zIndex: 1000,
          transform: "translateX(-50%)",
        }}
        h={80}
        gap={20}
        top={0}
        align="center"
        justify="center"
      >
        <Flex
          w="100%"
          align="center"
          px={{ base: 16, sm: 20, lg: 60 }}
          gap={20}
          justify="space-between"
          maw="1360px"
        >
          <Title order={1} h={{ base: 32, smm: 39 }}>
            <Text className="visually-hidden">Чайник</Text>
            <LogoTipsImg width="100%" height="100%" />
          </Title>

          <Flex
            id="navigate"
            component="nav"
            maw={781}
            flex={1}
            justify="space-between"
            wrap="nowrap"
            p="18px 0px"
            visibleFrom="lmd"
            style={{ textWrap: "nowrap", alignItems: "center" }}
          >
            {dataControls.map((item) => (
              <a
                style={{
                  cursor: "pointer",
                  color: "#2B2B2B",
                  textDecoration: "none",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                key={item.href}
                href={item.href}
                onClick={(event) => {
                  event.preventDefault();

                  if (equals(item.href.slice(0, 1), "/")) {
                    router.push(item.href);
                    return;
                  }

                  document.getElementById(item.href.slice(1))!.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
              >
                {item.text}
              </a>
            ))}
          </Flex>

          <Button
            radius={80}
            fw={600}
            onClick={() => {
              window.location.href = `https://${window.location.host}/auth`;
            }}
            variant="outline"
            visibleFrom="lmd"
          >
            Войти
          </Button>

          <Burger
            hiddenFrom="lmd"
            color="black"
            opened={menuOpened}
            onClick={toggleMenu}
          />
        </Flex>
      </Flex>
      <Stack pt={80} gap={30} h="100%" pb={{ sm: 20, base: 0 }}>
        <Box
          hiddenFrom="lmd"
          ref={menuRef}
          mx={16}
          mt={80}
          style={{
            position: "fixed",
            borderRadius: "16px",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            backgroundColor: "rgba(255, 255, 255, 1)",
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)",

            boxShadow: menuOpened ? "0 4px 30px rgba(0, 0, 0, 0.1)" : "none",
            transition: "transform 0.3s ease-in-out",
            transform: menuOpened
              ? "translateY(0)"
              : "translateY(calc(-100% - 88px))", // Высота шапки-100%)",
          }}
        >
          <Flex
            direction="column"
            align="center"
            justify="center"
            p={20}
            gap={16}
          >
            {dataControls.map((item) => (
              <a
                style={{
                  textDecoration: "none",
                  color: "#2B2B2B",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                key={item.href}
                href={item.href}
                onClick={(event) => {
                  event.preventDefault();

                  if (equals(item.href.slice(0, 1), "/")) {
                    router.push(item.href);
                    return;
                  }

                  document.getElementById(item.href.slice(1))!.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });

                  closeMenu();
                }}
              >
                {item.text}
              </a>
            ))}
            <Button
              onClick={() => {
                window.location.href = `https://${window.location.host}/auth`;
              }}
              color="white"
              c="black"
              h={20}
              fw={500}
              variant="white"
            >
              Войти
            </Button>
          </Flex>
        </Box>

        <Flex
          id="intro"
          gap={0}
          pos="relative"
          h="100%"
          mah={{ sm: 613 }}
          bg={{ smm: "white" }}
          p={{ smm: "24px 24px 24px 49px" }}
          style={{ borderRadius: 44, flexGrow: 1 }}
          direction={{ base: "column", smm: "row" }}
          align={{ base: "center", smm: "stretch" }}
        >
          <Stack
            gap={0}
            maw={572}
            justify="center"
            flex={{ base: 1, lmd: 2 }}
            mr={{ base: 10, smd: 30 }}
            ta={{ base: "center", smm: "left" }}
          >
            <Title order={2} fz={{ base: 28, xs: 44 }} lts={-0.5}>
              Получайте чаевые онлайн
            </Title>

            <Space h={{ base: 12, smm: 16 }} />

            <Text>
              Наш сервис избавляет от необходимости использовать наличные.
            </Text>
            <Text>
              С его помощью гость может с лёгкостью оставить отзыв и выразить
              благодарность персоналу
            </Text>

            <Space h={{ base: 20, smm: 44 }} />

            <Flex
              gap={{ base: 8, smm: 24 }}
              w={{ base: "100%", smm: "100%" }}
              justify="flex-start"
              direction={{ base: "column", smm: "row" }}
            >
              <Button
                fw={700}
                onClick={() => {
                  window.location.href = `https://${window.location.host}/auth?v=sign-up-org`;
                }}
                h={56}
              >
                Подключить заведение
              </Button>
              <Button
                fw={600}
                onClick={() => {
                  window.location.href = `https://${window.location.host}/auth?v=sign-up`;
                }}
                variant="outline"
                h={56}
              >
                Получать чаевые
              </Button>
            </Flex>
            {supportsPWA && (
              <Button
                mt={20}
                className="android-btn"
                maw={isMobile ? "100%" : 465}
                h={50}
                fw={500}
                onClick={handleInstallClick}
                radius={16}
                variant="light"
              >
                Установить приложение
              </Button>
            )}
          </Stack>

          <Space h={28} hiddenFrom="smm" />

          <Stack
            flex={1}
            maw={{ base: 572, smm: 466 }}
            mih={{ base: 352, smm: "100%" }}
            w="100%"
            miw={322}
            ml={{ smm: "auto" }}
            bg="linear-gradient(125deg, #3ed2ff, #2ccfff, #00b5ff, #0072ff, #3346ff, #413eff)"
            style={{ borderRadius: 36, overflow: "hidden" }}
            justify="flex-end"
            pos="relative"
          >
            <Box
              mah={{ base: 455, smm: 455 }}
              mb={{ base: -125, smm: 0 }}
              style={{ zIndex: 1 }}
            >
              <Image
                title="app"
                alt="app"
                fit="contain"
                h="100%"
                src={AppMainImage.src}
              />
            </Box>
            <Box pos="absolute" h={800} w={782} bottom={-250} right={-210}>
              <Image
                title="lights"
                alt="lights"
                fit="contain"
                h="100%"
                src={BgLights.src}
              />
            </Box>
          </Stack>
        </Flex>
      </Stack>
    </>
  );
};

export default Intro;
