"use client";
import { Flex, Stack, Text, Title } from "@mantine/core";
import Image from "next/image";
import { useIsMobile } from "@/shared/lib/hooks";
import {
  LandingPlans1,
  LandingPlans2,
  LandingPlans3,
  LandingPlans4,
  LandingPlans5,
  LandingPlansImg1,
  LandingPlansImg2,
  LandingPlansImg3,
  LandingPlansImg4,
  LandingPlansImg5,
} from "@/shared/model";

export const Plans = () => {
  const is1160 = useIsMobile(1160);
  const is900 = useIsMobile(900);
  const is1000 = useIsMobile(1000);
  const isMobile = useIsMobile(500);

  return (
    <Flex id="in-development" direction="column" gap="44px">
      <Stack>
        <Title
          order={2}
          style={{ zIndex: 2 }}
          ta="center"
          fz={isMobile ? 20 : 44}
        >
          Наши возможности в разработке
        </Title>
        <Text
          style={{ zIndex: 2 }}
          c="textGray"
          fw={400}
          ta="center"
          fz={{ base: 16, sm: 20 }}
        >
          Развиваем сервис, делая его удобнее и&nbsp;функциональнее. <br />{" "}
          Новые возможности для комфортной работы
        </Text>
      </Stack>
      <Flex direction="column" gap={{ md: 24, base: 16 }}>
        <Flex
          direction={is900 ? "column" : "row"}
          h={is900 ? "auto" : "366px"}
          gap={{ md: 24, base: 16 }}
        >
          <Flex
            style={{ borderRadius: "44px", overflow: "hidden" }}
            p={isMobile ? 24 : 40}
            w="100%"
            h={isMobile ? "fit-content" : 366}
            bg="#FE58D4"
            c="white"
            direction="column"
            pos="relative"
            gap={13}
          >
            <LandingPlans4 width="63px" height="63px" />
            <Title
              order={2}
              style={{ zIndex: 2 }}
              fz={isMobile ? 16 : 28}
              fw={700}
            >
              Бронирование
            </Title>
            <Text style={{ zIndex: 2 }} maw="267px" fz={16} fw={400} lh="24px">
              Функционал бронирования столов для гостей
            </Text>
            <Image
              src={LandingPlansImg1.src}
              alt="Бронирование"
              title="Бронирование"
              width={212}
              height={205}
              style={{
                objectFit: "contain",
                position: "absolute",
                bottom: isMobile ? -70 : 0,
                right: isMobile ? -40 : -13,
              }}
            />
          </Flex>
          <Flex
            h={isMobile ? "fit-content" : 366}
            style={{ borderRadius: "44px", overflow: "hidden" }}
            p={isMobile ? 24 : 40}
            w="100%"
            bg="#12AFFB"
            c="white"
            direction="column"
            pos="relative"
            gap={13}
          >
            <LandingPlans3 width="63px" height="63px" />
            <Title
              order={2}
              style={{ zIndex: 2 }}
              fz={isMobile ? 16 : 28}
              fw={700}
            >
              Чат-бот
            </Title>
            <Text style={{ zIndex: 2 }} maw="317px" fz={16} fw={400} lh="24px">
              Телеграм-бот для отслеживания чаевых и технической поддержки
            </Text>
            <LandingPlansImg2
              width="160px"
              alt="Чат-бот"
              height="160px"
              style={{
                position: "absolute",
                right: isMobile ? -40 : 0,
                bottom: isMobile ? -40 : 0,
              }}
            />
          </Flex>
          <Flex
            style={{ borderRadius: "44px", overflow: "hidden" }}
            p={isMobile ? 24 : 40}
            w="100%"
            h={isMobile ? "fit-content" : 366}
            bg="#316CFF"
            c="white"
            pos="relative"
            direction="column"
            gap={13}
          >
            <LandingPlans2 width="63px" height="63px" />
            <Title
              order={2}
              style={{ zIndex: 2 }}
              fz={isMobile ? 16 : 28}
              fw={700}
            >
              Виртуальная карта
            </Title>
            <Text style={{ zIndex: 2 }} maw="317px" fz={16} fw={400} lh="24px">
              Сотрудники смогут получать чаевые напрямую на банковскую
              карту&nbsp;и сразу же оплачивать с нее покупки
            </Text>
            <Image
              src={LandingPlansImg4.src}
              alt="Виртуальная карта"
              title="Виртуальная карта"
              width={180}
              height={180}
              style={{
                objectFit: "contain",
                position: "absolute",
                bottom: isMobile ? -30 : 0,
                right: isMobile ? -30 : 0,
              }}
            />
          </Flex>
        </Flex>
        <Flex direction={is900 ? "column" : "row"} gap={{ md: 24, base: 16 }}>
          <Flex
            h={isMobile ? "fit-content" : 348}
            style={{ borderRadius: "44px", overflow: "hidden" }}
            p={isMobile ? 24 : 40}
            w="100%"
            bg="#3F0060"
            c="white"
            pos="relative"
            direction="column"
            gap={13}
          >
            <LandingPlans1 width="63px" height="63px" />
            <Title
              order={2}
              style={{ zIndex: 2 }}
              fz={isMobile ? 16 : 28}
              fw={700}
            >
              Электронное меню
            </Title>
            <Text style={{ zIndex: 2 }} maw="347px" fz={16} fw={400} lh="24px">
              Позволит посетителям ресторанов быстро и&nbsp;удобно посмотреть
              меню. Гости смогут делать заказы напрямую через сервис,
              отсканировав QR-код
            </Text>
            <Image
              src={LandingPlansImg5.src}
              alt="Электронное меню"
              title="Электронное меню"
              width={236}
              height={170}
              style={{
                objectFit: "contain",
                position: "absolute",
                bottom: is1160 ? (isMobile ? -50 : -16) : 0,
                right: isMobile ? -60 : 16,
              }}
            />
          </Flex>
          <Flex
            h={isMobile ? "fit-content" : 348}
            style={{ borderRadius: "44px", overflow: "hidden" }}
            p={isMobile ? 24 : 40}
            w="100%"
            bg="#2DFF8F"
            pos="relative"
            c="#121212"
            direction="column"
            gap={13}
          >
            <LandingPlans5 width="63px" height="63px" />
            <Title
              order={2}
              style={{ zIndex: 2 }}
              fz={isMobile ? 16 : 28}
              fw={700}
            >
              Приложение
            </Title>
            <Text style={{ zIndex: 2 }} maw="379px" fz={16} fw={400} lh="24px">
              Управлять настройками и получать уведомления с помощью мобильного
              приложения удобнее на вашем смартфоне
            </Text>
            <Image
              src={LandingPlansImg3.src}
              alt="Приложение"
              title="Приложение"
              width={180}
              height={175}
              style={{
                objectFit: "contain",
                position: "absolute",
                bottom: isMobile ? -40 : 0,
                right: isMobile ? -40 : 0,
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
