"use client";
import { Box, Flex, Stack, Text, Title } from "@mantine/core";
import { useIsMobile } from "@/shared/lib/hooks";
import {
  LandingConditions1,
  LandingConditions2,
  LandingConditions3,
  LandingConditions4,
  LandingConditions5,
  LandingConditions6,
  LandingConditions7,
} from "@/shared/model/images";
import { Condition } from "@/shared/ui/display";

const OurConditions = () => {
  const is1000 = useIsMobile(1000);
  const isMobile = useIsMobile(600);
  return (
    <Stack
      justify="center"
      id="conditions"
      h="100%"
      mt={isMobile ? 20 : 50}
      gap={isMobile ? 24 : 44}
    >
      <Title fw={700} fz={isMobile ? 20 : 44} ta="center" order={2}>
        Наши условия
      </Title>
      <Flex
        direction={is1000 ? "column" : "row"}
        gap={{ md: 24, base: 16 }}
        h="100%"
      >
        <Stack
          p={isMobile ? 24 : 40}
          w="100%"
          h={!isMobile ? "378px" : "242px"}
          bg="white"
          pos="relative"
          style={{ borderRadius: "44px", overflow: "hidden" }}
        >
          <Condition when={isMobile}>
            <Title
              order={2}
              style={{ zIndex: 2, paddingRight: 80 }}
              fz={24}
              lh="32px"
              fw={700}
            >
              Подключение и&nbsp;обслуживание
            </Title>
            <Text fw={400} style={{ zIndex: 3 }} fz={16} lh="24px">
              QR-код для чаевых, интеграция с системой автоматизации, аналитика
              по бизнесу и пользование сервисом абсолютно бесплатно
            </Text>

            <Title
              style={{ zIndex: 3 }}
              pos="absolute"
              ta="center"
              top={20}
              right={30}
              fw={700}
              fz={48}
            >
              0 ₽
            </Title>
            <Box pos="absolute" w="550px" h="500px" top={-30} right={-100}>
              <LandingConditions7
                width="100%"
                height="100%"
                style={{
                  position: "absolute",
                  zIndex: 1,
                  right: -170,
                  top: -80,
                }}
              />
              <LandingConditions1
                width="100%"
                height="100%"
                style={{
                  height: "250px",
                  zIndex: 2,
                  mixBlendMode: "overlay",
                  position: "absolute",
                  top: 25,
                  right: 0,
                }}
              />
            </Box>
          </Condition>
          <Condition when={!isMobile}>
            <Title style={{ zIndex: 2 }} fz={24} fw={700}>
              Подключение и обслуживание
            </Title>
            <Text fw={400} style={{ zIndex: 3 }} fz={16} lh="24px">
              QR-код для чаевых, интеграция с системой автоматизации, аналитика
              по бизнесу и пользование сервисом абсолютно бесплатно
            </Text>

            <Title style={{ zIndex: 3 }} ta="center" fw={700} fz={104}>
              0 ₽
            </Title>
            <Box pos="absolute" w="100%" bottom={-30} right={0}>
              <LandingConditions2
                width="100%"
                height="100%"
                style={{
                  zIndex: 1,
                  right: 0,
                  bottom: 0,
                }}
              />
              <LandingConditions1
                width="100%"
                height="100%"
                style={{
                  bottom: 0,
                  mixBlendMode: "overlay",
                  position: "absolute",
                  right: 0,
                }}
              />
            </Box>
          </Condition>
        </Stack>
        <Flex direction="column" w="100%" gap={{ md: 24, base: 16 }}>
          <Condition when={!isMobile}>
            <Flex
              h={177}
              p={40}
              align="center"
              pos="relative"
              justify="space-between"
              style={{
                borderRadius: "44px 44px 44px 16px",
                overflow: "hidden",
              }}
              bg="white"
            >
              <Stack style={{ zIndex: 2 }}>
                <Title order={2} style={{ zIndex: 2 }} fz={28} fw={700}>
                  Чаевые{" "}
                </Title>
                <Text style={{ zIndex: 2 }} fw={400} fz={16} lh="24px">
                  Вывод чаевых на банковскую карту
                </Text>
              </Stack>
              <Title
                miw="fit-content"
                style={{ zIndex: 2 }}
                ta="center"
                fw={700}
                fz={80}
              >
                0 ₽
              </Title>
              <Box pos="absolute" h={"100%"} bottom={0} right={0}>
                <LandingConditions4
                  style={{
                    zIndex: 1,
                    right: 0,
                    top: 0,
                  }}
                  width="100%"
                  height="100%"
                />
                <LandingConditions3
                  style={{
                    mixBlendMode: "overlay",
                    position: "absolute",
                    right: 0,
                    top: 0,
                  }}
                  width="100%"
                  height="100%"
                />
              </Box>
            </Flex>
            <Flex
              h={177}
              p={40}
              align="center"
              pos="relative"
              justify="space-between"
              style={{
                borderRadius: "16px 44px 44px 44px",
                overflow: "hidden",
              }}
              bg="white"
            >
              <Stack style={{ zIndex: 2 }}>
                <Title order={2} fz={28} fw={700}>
                  Эквайринг{" "}
                </Title>
                <Text fw={400} fz={16} lh="24px">
                  Комиссия по эквайрингу <br /> при подключении оплаты счетов
                </Text>
              </Stack>
              <Title style={{ zIndex: 2 }} ta="center" fw={700} fz={80}>
                0,7%
              </Title>
              <Box pos="absolute" bottom={0} h={"100%"} right={0}>
                <LandingConditions6
                  style={{
                    zIndex: 1,
                    right: 0,
                  }}
                  width="100%"
                  height="100%"
                />
                <LandingConditions5
                  style={{
                    mixBlendMode: "overlay",
                    position: "absolute",
                    right: 0,
                  }}
                  width="100%"
                  height="100%"
                />
              </Box>
            </Flex>
          </Condition>
          <Condition when={isMobile}>
            <Flex
              h={177}
              p={isMobile ? 24 : 40}
              align="center"
              pos="relative"
              justify="space-between"
              style={{
                borderRadius: "44px 44px 44px 16px",
                overflow: "hidden",
              }}
              bg="white"
            >
              <Stack style={{ zIndex: 2 }}>
                <Title order={2} style={{ zIndex: 2 }} fz={24} fw={700}>
                  Чаевые{" "}
                </Title>
                <Text style={{ zIndex: 2 }} fw={400} fz={16} lh="24px">
                  Вывод чаевых на банковскую карту
                </Text>
              </Stack>
              <Title
                style={{ zIndex: 3 }}
                pos="absolute"
                ta="center"
                top={20}
                right={30}
                fw={700}
                fz={48}
              >
                0 ₽
              </Title>
              <Box pos="absolute" bottom={0} h={"100%"} right={-80}>
                <LandingConditions4
                  style={{
                    zIndex: 1,
                    right: 0,
                  }}
                  width="100%"
                  height="100%"
                />
                <LandingConditions3
                  style={{
                    mixBlendMode: "overlay",
                    position: "absolute",
                    right: 0,
                  }}
                  width="100%"
                  height="100%"
                />
              </Box>
            </Flex>
            <Flex
              h={177}
              p={isMobile ? 24 : 40}
              align="center"
              pos="relative"
              justify="space-between"
              style={{
                borderRadius: "16px 44px 44px 44px",
                overflow: "hidden",
              }}
              bg="white"
            >
              <Stack style={{ zIndex: 2 }}>
                <Title order={2} fz={24} fw={700}>
                  Эквайринг{" "}
                </Title>
                <Text fw={400} fz={16} lh="24px">
                  Комиссия по эквайрингу <br /> при подключении оплаты счетов
                </Text>
              </Stack>
              <Title
                style={{ zIndex: 3 }}
                pos="absolute"
                ta="center"
                top={20}
                right={30}
                fw={700}
                fz={48}
              >
                0,7%
              </Title>
              <Box pos="absolute" bottom={0} h={"100%"} right={-80}>
                <LandingConditions6
                  style={{
                    zIndex: 1,
                    right: 0,
                  }}
                  width="100%"
                  height="100%"
                />
                <LandingConditions5
                  style={{
                    mixBlendMode: "overlay",
                    position: "absolute",
                    right: 0,
                  }}
                  width="100%"
                  height="100%"
                />
              </Box>
            </Flex>
          </Condition>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default OurConditions;
