"use client";
import { Button, Flex, Stack, Text, Title } from "@mantine/core";
import { useIsMobile } from "@/shared/lib/hooks";
import {
  SupportBg1,
  SupportBg2,
  MailIcon,
  TelegramBlack,
} from "@/shared/model";
import { LogoImg } from "@/shared/model/images";

const Support = () => {
  const isMobile = useIsMobile(730);
  const is600 = useIsMobile(600);
  return (
    <Stack
      style={{
        borderRadius: "44px",
        overflow: "hidden",
      }}
      pos="relative"
      p={40}
      bg="white"
      justify="center"
      w="100%"
      h="100%"
      mah="fit-content"
      align="center"
      gap={16}
      mx="auto"
      id="support"
    >
      <SupportBg1
        width="100%"
        height="100%"
        style={{ position: "absolute", right: 0, bottom: 0 }}
      />
      <SupportBg2
        width="100%"
        height="100%"
        style={{ position: "absolute", left: 0, bottom: 0 }}
      />
      <LogoImg width={40} height={40} preserveAspectRatio="xMidYMid meet" />

      <Title
        style={{ zIndex: 1 }}
        fw={700}
        fz={is600 ? 20 : 44}
        ta="center"
        order={2}
      >
        Поддержка
      </Title>
      <Text
        style={{ zIndex: 1 }}
        ta="center"
        c="textGray"
        fz={is600 ? 16 : 20}
        fw={400}
      >
        Наша команда поможет в любой ситуации. <br /> Отладка, подключение,
        дизайн — мы решим любую задачу! <br /> Мы всегда на связи, быстро
        ответим и поможем
      </Text>
      <Flex
        style={{ zIndex: 1 }}
        w="100%"
        align="center"
        justify="center"
        c="black"
        direction={isMobile ? "column" : "row"}
        gap={20}
        mx="auto"
      >
        <Button
          maw={286}
          radius={100}
          h={56}
          onClick={() => window.open("mailto: support@chainik.pro")}
          w="100%"
          c="black"
          px={0}
          variant="white"
        >
          <MailIcon height={32} width={32} />
          <Text ml={12} fz={20} fw={500}>
            support@chainik.pro
          </Text>
        </Button>
        <Button
          onClick={() => window.open("https://t.me/chainik_help")}
          maw={286}
          radius={100}
          h={56}
          w="100%"
          c="black"
          variant="white"
        >
          <TelegramBlack height={32} width={32} />
          <Text ml={12} fz={20} fw={500}>
            Telegram
          </Text>
        </Button>
      </Flex>
    </Stack>
  );
};

export default Support;
