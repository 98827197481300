import { Divider, Flex, Text, Title } from "@mantine/core";
import { JSX } from "react";

type Props = {
  title: string;
  description: string | JSX.Element;
};

const OurConditionsCard = ({ title, description }: Props) => {
  return (
    <Flex justify="flex-end" align="flex-end" gap={16}>
      <Title order={2} fw={900} miw="fit-content" fz="70px" lh="66px">
        {title}
      </Title>
      <Divider orientation="vertical" />
      <Text style={{ alignSelf: "flex-start" }} fw={500} fz={20}>
        {description}
      </Text>
    </Flex>
  );
};

export default OurConditionsCard;
