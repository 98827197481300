"use client";
import {
  Box,
  getThemeColor,
  Image,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useIsMobile } from "@/shared/lib/hooks";
import {
  LandingPosib1,
  LandingPosib2,
  LandingPosib3,
  LandingPosib4,
} from "@/shared/model";
import { ImgGradeExcellent } from "@/shared/model/raster-images";
import { FacilitiesCard } from "@/features";

const Facilities = () => {
  const theme = useMantineTheme();
  const is1100 = useIsMobile(1100);
  const isMobile = useIsMobile(600);
  return (
    <Stack w="100%" id="opportunities" gap={isMobile ? 20 : 60}>
      <Title order={2} fz={isMobile ? 20 : 44} fw={700} ta="center">
        Наши возможности
      </Title>
      <div>
        <Box
          display="grid"
          style={{
            gridTemplateColumns: is1100
              ? isMobile
                ? "repeat(1, 1fr)"
                : "repeat(2, 1fr)"
              : "repeat(4, 1fr)",
            gap: "24px",
          }}
          w="100%"
          pos="relative"
        >
          <Stack
            justify="center"
            align="center"
            pos="absolute"
            p={8}
            gap={4}
            h={85}
            w={140}
            bg="#E9EBF9"
            style={{
              borderRadius: "20px",
              border: `2px solid ${getThemeColor("brand.6", theme)}`,
              zIndex: 2,
              boxShadow: "0px 10.56px 10.56px 0px #F1F5F9",
              transform: `${
                isMobile
                  ? "scale(0.8) translateY(-10%) rotate(-30deg)  translateX(-15%) "
                  : "translateY(-20%) rotate(-30deg)  translateX(-30%) "
              }`,
            }}
          >
            <Image
              alt="Прекрасно!"
              title="Прекрасно!"
              h="31px"
              w="31px"
              src={ImgGradeExcellent.src}
            />
            <Text
              ta="center"
              c={getThemeColor("brand.6", theme)}
              fz={16}
              fw={400}
            >
              Прекрасно!
            </Text>
          </Stack>
          <FacilitiesCard
            title="Чаевые и отзывы"
            description="Ваши отзывы важны! Сканируйте QR-код, помогите нам стать лучше"
            image={
              <Image
                alt="Чаевые и отзывы"
                title="Чаевые и отзывы"
                h={is1100 ? (isMobile ? 123 : 170) : 144}
                style={{ objectFit: "contain" }}
                src={LandingPosib1.src}
                mt={is1100 ? 44 : 20}
              />
            }
          />
          <FacilitiesCard
            title="Оплата счёта"
            description="Оплачивайте безопасно и&nbsp;мгновенно — без очередей&nbsp;и лишних действий"
            image={
              <LandingPosib2
                height="100%"
                style={{
                  position: "absolute",
                  top: -80,
                  maxWidth: "400px",
                  width: isMobile ? 341 : "100%",
                  left: !is1100 ? 0 : "50%",
                  transform: !is1100 ? "translateX(0)" : "translateX(-50%)",
                }}
              />
            }
          />{" "}
          <FacilitiesCard
            title="Статистика"
            description="Следите за прогрессом, анализируйте статистику и&nbsp;находите точки роста"
            image={
              <LandingPosib3
                width="100%"
                height="100%"
                style={{
                  position: "absolute",
                  maxWidth: "400px",

                  top: -80,
                  left: !is1100 ? 0 : "50%",
                  transform: !is1100 ? "translateX(0)" : "translateX(-50%)",
                }}
              />
            }
          />
          <FacilitiesCard
            title="Интеграция
с iiko и R-keeper"
            description="
            Улучшите сервис с&nbsp;iiko/r_keeper — QR-коды в&nbsp;пречеке ускорят обслуживание"
            image={
              <LandingPosib4
                width="100%"
                height="100%"
                style={{
                  maxWidth: "320px",

                  position: "absolute",
                  top: -80,
                  right: "50%",

                  padding: "0 10px",
                  transform: "translateX(50%)",
                }}
              />
            }
          />
        </Box>
      </div>
    </Stack>
  );
};

export default Facilities;
